<template>
  <div>
    <div class="gva-search-box">
      <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
        <el-form-item>
          <el-button size="small" type="primary" icon="search" @click="onSubmit">查询</el-button>
          <el-button size="small" icon="refresh" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="gva-table-box">
        <div class="gva-btn-list">
            <el-button size="small" type="primary" icon="plus" @click="openDialog">新增</el-button>
            <el-popover v-model:visible="deleteVisible" placement="top" width="160">
            <p>确定要删除吗？</p>
            <div style="text-align: right; margin-top: 8px;">
                <el-button size="small"  text=true @click="deleteVisible = false">取消</el-button>
                <el-button size="small" type="primary" @click="onDelete">确定</el-button>
            </div>
            <template #reference>
                <el-button icon="delete" size="small" style="margin-left: 10px;" :disabled="!multipleSelection.length">删除</el-button>
            </template>
            </el-popover>
        </div>
        <el-table
        ref="multipleTable"
        style="width: 100%"
        tooltip-effect="dark"
        :data="tableData"
        row-key="id"
        @selection-change="handleSelectionChange"
        >
        <el-table-column type="selection" width="55" />
        <el-table-column align="left" label="日期" width="180">
            <template #default="scope">{{ formatDate(scope.row.CreatedAt) }}</template>
        </el-table-column>
        <el-table-column align="left" label="uuid字段" prop="uuid" width="120" />
        <el-table-column align="left" label="A相平均放电量" prop="aAveDischarge" width="120" />
        <el-table-column align="left" label="A相放电次数" prop="aDischargeCount" width="120" />
        <el-table-column align="left" label="A相放电类型" prop="aDischargeType" width="120" />
        <el-table-column align="left" label="A相最大放电量" prop="aMaxDischarge" width="120" />
        <el-table-column align="left" label="bAveDischarge字段" prop="bAveDischarge" width="120" />
        <el-table-column align="left" label="bDischargeCount字段" prop="bDischargeCount" width="120" />
        <el-table-column align="left" label="bDischargeType字段" prop="bDischargeType" width="120" />
        <el-table-column align="left" label="bMaxDischarge字段" prop="bMaxDischarge" width="120" />
        <el-table-column align="left" label="cAveDischarge字段" prop="cAveDischarge" width="120" />
        <el-table-column align="left" label="cDischargeCount字段" prop="cDischargeCount" width="120" />
        <el-table-column align="left" label="cDischargeType字段" prop="cDischargeType" width="120" />
        <el-table-column align="left" label="cMaxDischarge字段" prop="cMaxDischarge" width="120" />
        <el-table-column align="left" label="数据时间" prop="date" width="120" />
        <el-table-column align="left" label="设备在线状态" prop="online" width="120">
            <template #default="scope">{{ formatBoolean(scope.row.online) }}</template>
        </el-table-column>
        <el-table-column align="left" label="按钮组">
            <template #default="scope">
            <el-button  text=true icon="edit" size="small" class="table-button" @click="updatePartialDischargeMonitorDataFunc(scope.row)">变更</el-button>
            <el-button  text=true icon="delete" size="small" @click="deleteRow(scope.row)">删除</el-button>
            </template>
        </el-table-column>
        </el-table>
        <div class="gva-pagination">
            <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 30, 50, 100]"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            />
        </div>
    </div>
    <el-dialog v-model="dialogFormVisible" :before-close="closeDialog" title="弹窗操作">
      <el-form :model="formData" label-position="right" label-width="80px">
        <el-form-item label="uuid字段:">
          <el-input v-model="formData.uuid" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="A相平均放电量:">
          <el-input-number v-model="formData.aAveDischarge"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="A相放电次数:">
          <el-input-number v-model="formData.aDischargeCount"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="A相放电类型:">
          <el-input-number v-model="formData.aDischargeType"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="A相最大放电量:">
          <el-input-number v-model="formData.aMaxDischarge"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="bAveDischarge字段:">
          <el-input-number v-model="formData.bAveDischarge"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="bDischargeCount字段:">
          <el-input-number v-model="formData.bDischargeCount"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="bDischargeType字段:">
          <el-input-number v-model="formData.bDischargeType"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="bMaxDischarge字段:">
          <el-input-number v-model="formData.bMaxDischarge"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="cAveDischarge字段:">
          <el-input-number v-model="formData.cAveDischarge"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="cDischargeCount字段:">
          <el-input-number v-model="formData.cDischargeCount"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="cDischargeType字段:">
          <el-input-number v-model="formData.cDischargeType"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="cMaxDischarge字段:">
          <el-input-number v-model="formData.cMaxDischarge"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="数据时间:">
          <el-input v-model.number="formData.date" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="设备在线状态:">
          <el-switch v-model="formData.online" active-color="#13ce66" inactive-color="#ff4949" active-text="是" inactive-text="否" clearable ></el-switch>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
          <el-button size="small" type="primary" @click="enterDialog">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'PartialDischargeMonitorData'
}
</script>

<script setup>
import {
  createPartialDischargeMonitorData,
  deletePartialDischargeMonitorData,
  deletePartialDischargeMonitorDataByIds,
  updatePartialDischargeMonitorData,
  findPartialDischargeMonitorData,
  getPartialDischargeMonitorDataList
} from '@/api/partialDischargeMonitorData'

// 全量引入格式化工具 请按需保留
import { getDictFunc, formatDate, formatBoolean, filterDict } from '@/utils/format'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref } from 'vue'
import service from '@/utils/request'

// 自动化生成的字典（可能为空）以及字段
const formData = ref({
        uuid: '',
        aAveDischarge: 0,
        aDischargeCount: 0,
        aDischargeType: 0,
        aMaxDischarge: 0,
        bAveDischarge: 0,
        bDischargeCount: 0,
        bDischargeType: 0,
        bMaxDischarge: 0,
        cAveDischarge: 0,
        cDischargeCount: 0,
        cDischargeType: 0,
        cMaxDischarge: 0,
        date: 0,
        online: false,
        })

// =========== 表格控制部分 ===========
const page = ref(1)
const total = ref(0)
const pageSize = ref(10)
const tableData = ref([])
const searchInfo = ref({})

// 重置
const onReset = () => {
  searchInfo.value = {}
}

// 搜索
const onSubmit = () => {
  page.value = 1
  pageSize.value = 10
  if (searchInfo.value.online === ""){
      searchInfo.value.online=null
  }
  getTableData()
}

// 分页
const handleSizeChange = (val) => {
  pageSize.value = val
  getTableData()
}

// 修改页面容量
const handleCurrentChange = (val) => {
  page.value = val
  getTableData()
}


// 查询
const getTableData = async () => {
  //const table = await getDevModelsList({ page: page.value, pageSize: pageSize.value, ...searchInfo.value })
  const table = await service({
    url: '/api/v2/device/getDeviceDischargeDataByPage',
   // url: "/api/v2/model/query",
    method: "post",
    data:{ page: page.value, pageSize: pageSize.value, ...searchInfo.value }
    //data: {},
  })
    .then((res) => {
      if (res.code === 0) {
        if (res.data.list) {
          tableData.value = res.data.list;
          total.value = res.data.total;
          page.value = res.data.page;
          pageSize.value = res.data.pageSize;
        } else {
          tableData.value = res.data;
          total.value = res.data.length;
        }
      }
    })
    .catch((e) => {
      console.log(e);
    });
};
getTableData()

// ============== 表格控制部分结束 ===============

// 获取需要的字典 可能为空 按需保留
const setOptions = async () =>{
}

// 获取需要的字典 可能为空 按需保留
setOptions()


// 多选数据
const multipleSelection = ref([])
// 多选
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}

// 删除行
const deleteRow = (row) => {
    ElMessageBox.confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
            deletePartialDischargeMonitorDataFunc(row)
        })
    }


// 批量删除控制标记
const deleteVisible = ref(false)

// 多选删除
const onDelete = async() => {
      const ids = []
      if (multipleSelection.value.length === 0) {
        ElMessage({
          type: 'warning',
          message: '请选择要删除的数据'
        })
        return
      }
      multipleSelection.value &&
        multipleSelection.value.map(item => {
          ids.push(item.id)
        })
      const res = await deletePartialDischargeMonitorDataByIds({ ids })
      if (res.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功'
        })
        if (tableData.value.length === ids.length && page.value > 1) {
          page.value--
        }
        deleteVisible.value = false
        getTableData()
      }
    }

// 行为控制标记（弹窗内部需要增还是改）
const type = ref('')

// 更新行
const updatePartialDischargeMonitorDataFunc = async(row) => {
    const res = await findPartialDischargeMonitorData({ id: row.id })
    type.value = 'update'
    if (res.code === 0) {
        formData.value = res.data.redevPartialDischargeMonitorData
        dialogFormVisible.value = true
    }
}


// 删除行
const deletePartialDischargeMonitorDataFunc = async (row) => {
    const res = await deletePartialDischargeMonitorData({ id: row.id })
    if (res.code === 0) {
        ElMessage({
                type: 'success',
                message: '删除成功'
            })
            if (tableData.value.length === 1 && page.value > 1) {
            page.value--
        }
        getTableData()
    }
}

// 弹窗控制标记
const dialogFormVisible = ref(false)

// 打开弹窗
const openDialog = () => {
    type.value = 'create'
    dialogFormVisible.value = true
}

// 关闭弹窗
const closeDialog = () => {
    dialogFormVisible.value = false
    formData.value = {
        uuid: '',
        aAveDischarge: 0,
        aDischargeCount: 0,
        aDischargeType: 0,
        aMaxDischarge: 0,
        bAveDischarge: 0,
        bDischargeCount: 0,
        bDischargeType: 0,
        bMaxDischarge: 0,
        cAveDischarge: 0,
        cDischargeCount: 0,
        cDischargeType: 0,
        cMaxDischarge: 0,
        date: 0,
        online: false,
        }
}
// 弹窗确定
const enterDialog = async () => {
      let res
      switch (type.value) {
        case 'create':
          res = await createPartialDischargeMonitorData(formData.value)
          break
        case 'update':
          res = await updatePartialDischargeMonitorData(formData.value)
          break
        default:
          res = await createPartialDischargeMonitorData(formData.value)
          break
      }
      if (res.code === 0) {
        ElMessage({
          type: 'success',
          message: '创建/更改成功'
        })
        closeDialog()
        getTableData()
      }
}
</script>

<style>
</style>
