import service from '@/utils/request'

// @Tags PartialDischargeMonitorData
// @Summary 创建PartialDischargeMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PartialDischargeMonitorData true "创建PartialDischargeMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devPartialDischargeMonitorData/createPartialDischargeMonitorData [post]
export const createPartialDischargeMonitorData = (data) => {
  return service({
    url: '/devPartialDischargeMonitorData/createPartialDischargeMonitorData',
    method: 'post',
    data
  })
}

// @Tags PartialDischargeMonitorData
// @Summary 删除PartialDischargeMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PartialDischargeMonitorData true "删除PartialDischargeMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devPartialDischargeMonitorData/deletePartialDischargeMonitorData [delete]
export const deletePartialDischargeMonitorData = (data) => {
  return service({
    url: '/devPartialDischargeMonitorData/deletePartialDischargeMonitorData',
    method: 'delete',
    data
  })
}

// @Tags PartialDischargeMonitorData
// @Summary 删除PartialDischargeMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除PartialDischargeMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devPartialDischargeMonitorData/deletePartialDischargeMonitorData [delete]
export const deletePartialDischargeMonitorDataByIds = (data) => {
  return service({
    url: '/devPartialDischargeMonitorData/deletePartialDischargeMonitorDataByIds',
    method: 'delete',
    data
  })
}

// @Tags PartialDischargeMonitorData
// @Summary 更新PartialDischargeMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PartialDischargeMonitorData true "更新PartialDischargeMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /devPartialDischargeMonitorData/updatePartialDischargeMonitorData [put]
export const updatePartialDischargeMonitorData = (data) => {
  return service({
    url: '/devPartialDischargeMonitorData/updatePartialDischargeMonitorData',
    method: 'put',
    data
  })
}

// @Tags PartialDischargeMonitorData
// @Summary 用id查询PartialDischargeMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.PartialDischargeMonitorData true "用id查询PartialDischargeMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /devPartialDischargeMonitorData/findPartialDischargeMonitorData [get]
export const findPartialDischargeMonitorData = (params) => {
  return service({
    url: '/devPartialDischargeMonitorData/findPartialDischargeMonitorData',
    method: 'get',
    params
  })
}

// @Tags PartialDischargeMonitorData
// @Summary 分页获取PartialDischargeMonitorData列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取PartialDischargeMonitorData列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devPartialDischargeMonitorData/getPartialDischargeMonitorDataList [get]
export const getPartialDischargeMonitorDataList = (params) => {
  return service({
    url: '/devPartialDischargeMonitorData/getPartialDischargeMonitorDataList',
    method: 'get',
    params
  })
}
